$height-offset-small: 131.16px;
$height-offset-xsmall: 153.16px;

body.va-pos-fixed {
  width: 100%;
  height: 100%;
}

#vetnav,
[role="menu"] [role="menu"] {
  // The main menu takes up the whole screen under the Menu button for xsmall & small screens
  // Sub menus also take up the whole screen to cover the main menu
  min-height: calc(100vh - #{$height-offset-xsmall});

  @include media($small-screen) {
    min-height: calc(100vh - #{$height-offset-small});
  }

  // For medium and large screens, they're just as big as they need to be
  @include media($medium-screen) {
    min-height: 100%;
  }
}

#vetnav {
  background-color: var(--vads-color-primary-darker);

  // This value should be equal to the default y-position of the menu so that removing it
  // shouldn't affect anything. However, it's here to show how the height is offset by the
  // vertical positioning.
  // top: $height-offset;
  left: -0.5625rem;
  overflow-y: scroll;
  position: absolute;
  width: 103%;

  // Just large enough to take precedence over any other dropdowns on the page and the VA Map

  &.vetnav--submenu-expanded {
    #vetnav-menu {
      overflow: hidden;
      height: 0;

      @include media($medium-screen) {
        height: auto;
        overflow: visible;
      }
    }
  }

  @include media($medium-screen) {
    margin-top: 0.625rem;
    overflow-y: visible;
    position: relative;
    width: auto;
    top: 0;
  }
}

#vetnav-menu {
  height: auto;
  list-style: none;
  margin: 0;
  padding-left: 0;

  @include media($medium-screen) {
    display: flex;
    height: auto;
    margin-top: 0.625rem;
    margin-bottom: 0;
    overflow-y: visible;
    position: relative;
  }

  > li {
    margin-bottom: 6px;

    @include media($medium-screen) {
      margin-bottom: 0;
    }

    &:first-of-type {
      @include media($medium-screen) {
        display: none;
      }
    }
  }

  &::before {
    display: block;
    content: " ";
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .usa-button-secondary {
    padding: calc(0.625rem + 2px);
    margin: 0.5rem 1rem;
    width: auto;
    background-color: var(--vads-color-base-lightest);

    &:hover {
      text-decoration: none;
    }

    @include media($medium-screen) {
      display: inline-block;
      background-color: transparent;

      &.vetnav-level2-button-second {
        margin-left: 0;
      }
    }
  }
}

#vetnav-controls {
  position: relative;
  width: 100px;

  svg {
    display: inline-block;
    height: 1rem;
    margin-right: 0.5rem;
    width: 0.625rem;
    vertical-align: 0;
  }

  path {
    fill: var(--vads-color-white);
  }

  @include media($medium-screen) {
    display: none;
  }
}

[class^="vetnav-controller"] {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  min-width: 100px;
  width: auto;

  &[hidden] {
    display: none;
  }
}

@include media($medium-screen) {
  a.vetnav-level1 {
    //border-bottom: 1px solid transparent;

    &:hover {
      background-color: var(--vads-color-primary);
      border-bottom-color: var(--vads-color-primary);
      transition: none;
    }
  }
}

.vetnav-level1 {
  -webkit-font-smoothing: antialiased;
  background-color: var(--vads-color-primary-dark);
  background-position: right 1.875rem center;
  background-repeat: no-repeat;
  background-size: 0.8125rem auto;
  border-radius: 0;
  border-top: 3px solid transparent;
  color: var(--vads-color-white);
  cursor: pointer;
  display: block;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  padding: 1rem;
  text-align: left;
  text-decoration: none;
  width: 100%;

  &:not([href]) {
    background-image: url(/img/minus-white.svg);

    @include media($medium-screen) {
      background-position: right 1rem center;
      padding-right: 2rem;
    }
  }

  &[aria-expanded="true"] {
    @include media($medium-screen) {
      color: var(--vads-color-base);
      background-color: var(--vads-color-white);
      background-image: url(/img/arrow-up.svg);
      border-top-color: var(--vads-color-va-accent);
    }
  }

  &[aria-expanded="false"] {
    background-image: url(/img/plus-white.svg);

    &:hover {
      background-color: var(--vads-color-primary);
    }

    @include media($medium-screen) {
      background-image: url(/img/arrow-down-white.svg);
    }
  }

  @include media($medium-screen) {
    background-color: transparent;
    background-size: 0.625rem auto;
  }

  &:active {
    color: inherit;
  }

  &:hover {
    text-decoration: none;
  }

  &:visited,
  &:active {
    color: inherit;
  }
}

.vetnav-level2 {
  background: var(--vads-color-primary-darker) url(/img/arrow-right-white.svg) right 1.25rem center no-repeat;
  background-size: 0.875rem auto;
  border-radius: 0;
  display: block;
  font-weight: normal;
  margin: 0;
  padding: 1rem;
  text-align: left;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1;

  @include media($medium-screen) {
    background: transparent url(/img/arrow-right.svg) right 0.625rem center no-repeat;
    border-color: transparent;
    border-style: solid;
    border-width: 1px 0;
    color: var(--vads-color-link);
    background-size: 0.625rem auto;
    margin-left: 0.5rem;
    padding: 0.5rem 0 0.5rem 1rem;
    position: relative;
    width: 15.8125rem;

    &:hover {
      background-color: transparent;
      border-bottom: 1px solid transparent;
      box-shadow: -3px 0 var(--vads-color-va-accent);
      color: var(--vads-color-primary);
      text-decoration: underline;
    }

    &[aria-expanded="true"] {
      background-color: var(--vads-color-base-lightest);
      border-bottom-color: var(--vads-color-gray-warm-light);
      border-top-color: var(--vads-color-gray-warm-light);
      box-shadow: -3px 0 var(--vads-color-va-accent);
      color: var(--vads-color-base-darker);
      font-weight: bold;
      text-decoration: inherit;
      position: relative;
    }
  }

  &-heading {
    -webkit-font-smoothing: antialiased;
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0.5rem 1rem;

    @include media($medium-screen) {
      display: none;
    }
  }
}

#vetnav .back-button {
  background: var(--vads-color-primary-dark) url(/img/arrow-left-white.svg) right 1.875rem center no-repeat;
  background-position: left 0.625rem center;
  background-size: 0.875rem auto;
  border-radius: 0;
  padding: 1rem 1rem 1rem 1.875rem;
  text-align: left;
  width: 100%;
  margin: 0;

  @include media($medium-screen) {
    display: none;
  }
}

.vetnav-panel {
  a {
    @include no-sr-content;

    background-image: none;
    color: var(--vads-color-white);
    display: block;
    text-decoration: none;

    @include media($medium-screen) {
      color: var(--vads-color-link);

      &:hover {
        background: transparent;
        text-decoration: underline;
      }
    }
  }

  .mm-links {
    padding: 0.5rem 1rem;
  }

  @include media($medium-screen) {
    box-shadow: 0 5px 9px -5px var(--vads-color-base);
    background: var(--vads-color-white);
    padding-bottom: 1rem;
    position: absolute;
  }
}

#mega-menu #vetnav-va-benefits-and-health-care {

  &,
  & .vetnav-panel.vetnav-panel--submenu {
    @media screen and (min-width: 768px) {
      height: 490px !important;
    }
  }
}

#vetnav-va-benefits-and-health-care.vetnav-panel {
  width: 250px;

  @include media($medium-screen) {
    &.vetnav-submenu--expanded {
      width: 768px;
    }
  }

  @include media($small-desktop-screen) {
    &.vetnav-submenu--expanded {
      width: 1008px;
    }
  }
}

.vetnav-panel--submenu {
  &:not([hidden]) {
    background-color: var(--vads-color-primary-darker);
    box-shadow: none;
    position: absolute;
    width: 100%;
    top: 0;
    visibility: visible;
    margin: 0;

    @include media($medium-screen) {
      border-left: 1px solid var(--vads-color-gray-warm-light);
      background-color: var(--vads-color-base-lightest);
      height: 100%;
      margin-left: 14.375rem;
      left: 30px;
      padding: 0.3125rem 0 1rem;
      width: 19.375rem;
    }
  }
}

.vet-toolbar {
  align-items: center;
  flex: 1 1 100%;
  margin-left: -1.25rem;
  position: relative;

  > [data-reactroot],
  > div {
    align-items: center;
    display: flex;
  }

  @include media($medium-screen) {
    flex: 0 1 20.3125rem;
    margin-left: 0;
    margin-right: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  .va-dropdown {
    margin-left: 0.625rem;
  }

  .va-dropdown-trigger {
    font-weight: 600;
  }
}

[id="search"] {
  width: 18.75rem;

  [type="text"] {
    margin: 0;
    flex: 1 0 12.5rem;
  }

  [type="submit"] {
    border-radius: 0 0.1875rem 0.1875rem 0;
    display: flex;
    flex: 1 0 1.875rem;
    justify-content: center;
    margin: 0;
    padding: 0.625rem;
  }

  > .va-flex {
    align-items: center;
  }
}

@include media($medium-screen) {
  .child-menu-opened {
    height: auto;
  }
}

.merger {
  #vetnav {
    // 100% width necessary for right aligned items
    width: 100%;
    left: initial;
  }

  @media (min-width: $xsmall-screen) and (max-width: $medium-screen - 1) {
    #mega-menu {
      .login-container {
        position: relative;
        top: -40px;
      }
    }

    .mega-menu {
      .login-container {
        // due to these been nested, this has to have a higher z-level then vet-toolbar
        position: absolute;
        bottom: 40px;
        width: 100%;
        left: 0;

        #vetnav {
          left: 0;
        }
      }
    }

    #vetnav {
      // 100% width necessary for right aligned items
      width: 100%;
      left: initial;
    }

    #vetnav-controls {
      margin-top: 7px;

      svg {
        margin-left: 0.5rem;
      }
    }

    .mm-link-container {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  // TD: need to update this on formation in the MegaMenu. If no title it should still align with the link row.
  #vetnav-records-ms {
    & + div {
      .column-two h3 {
        visibility: hidden;
      }
    }
  }

  #va-nav-controls {
    position: relative;
    width: 100px;

    svg {
      display: inline-block;
      height: 1rem;
      margin-left: 0.5rem;
      width: 0.625rem;
      vertical-align: 0;
    }

    path {
      fill: var(--vads-color-white);
    }

    @include media($medium-screen) {
      display: none;
    }
  }

  .vetnav-panel {
    @include media($medium-screen) {
      box-shadow: 0 5px 9px -3px var(--vads-color-base);
    }
  }

  // Toggle button on mobile
  [class^="vetnav-controller"] {
    position: relative !important;
  }
}

// Safari needs this to correctly display megamenu
.mega-menu,
#mega-menu {
  .login-container {
    .row {
      &.va-flex {
        display: grid;
      }
    }
  }
}