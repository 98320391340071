.form-expanding-group {
  &.form-expanding-group-open {
    > span {
      display: inline;
    }
  }
  > span {
    display: none;
  }

  &.form-expanding-group-open.borderless-alert {
    border: none;
    padding-left: 1.25rem;

    ul {
      padding-left: 1.5em;
    }
  }
}
