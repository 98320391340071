@import "~@department-of-veterans-affairs/css-library/dist/tokens/scss/variables";

.homepage-hero {
  &__wrapper {
    background: linear-gradient(
      to left,
      var(--vads-color-primary) 30%,
      var(--vads-color-primary-dark) 60%,
      var(--vads-color-primary-darker)
    );
  }

  &__container {
    display: flex;
    width: 100%;
  }

  &__welcome-headline {
    max-width: 100%;

    @media (min-width: $small-desktop-screen) {
      max-width: 50%;
    }
  }

  &__create-account {
    border-radius: 5px;
    margin: 20px;
    @media (min-width: $small-desktop-screen) {
      margin: 0;
      margin-left: 172px;
    }
  }

  // these styles overwrite USWDS button styles and are specific to the homepage hero
  &__button {
    color: var(--vads-color-primary);
    background-color: var(--vads-color-white);

    &:hover,
    &:active {
      background-color: var(--vads-color-primary-dark);
      outline-offset: 2px;
    }

    &:hover,
    &:focus,
    &:visited {
      color: var(--vads-color-white);
      text-decoration: none;
      outline-offset: 2px;
    }
  }
}

.homepage-hero__wrapper.fallback {
  background: linear-gradient(
    to right,
    var(--vads-color-primary) 30%,
    var(--vads-color-primary-dark) 60%,
    var(--vads-color-primary-darker)
  );

  .homepage-hero__create-account {
    margin: 0 !important;
  }

  h1 {
    margin: 28px 0 16px 0;
    max-width: 228px;

    @media screen and (max-width: 539px) {
      max-width: 100%;
      width: 100%;
    }
  }

  h2 {
    margin: 0 0 12px 0;

    @media screen and (max-width: 539px) {
      max-width: 85%;
    }
  }

  a {
    margin-bottom: 20px;
  }

  img {
    display: inline-block;
    height: 327px;
    width: 33.3%;
    object-fit: cover;

    @media screen and (max-width: 1007px) {
      width: 50%;
      object-position: top;

      &:last-child {
        display: none;
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;

      &:nth-child(2) {
        display: none;
      }
    }
  }

  button {
    margin: 0 0 30px 0;
  }

  .homepage-hero-first-column {
    width: 100%;
    max-width: 400px;
    padding-left: 17px;

    @media screen and (max-width: 899px) {
      max-width: 345px;
    }

    @media screen and (max-width: 767px) {
      max-width: 60%;
      padding-left: 12px;
    }

    @media screen and (max-width: 539px) {
      max-width: 100%;
      padding-right: 9px !important;
    }
  }

  .homepage-hero-second-column {
    padding-right: 17px;

    @media screen and (max-width: 1007px) {
      width: 60%;
    }

    @media screen and (max-width: 899px) {
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      width: 40%;
      padding-right: 9px;
    }

    @media screen and (max-width: 539px) {
      display: none;
    }
  }
}

.homepage-common-tasks {
  &__wrapper {
    background: linear-gradient(
      to bottom,
      var(--vads-color-white) 0 40%,
      var(--vads-color-primary-alt-lightest) 40% 100%
    );
  }

  &__list {
    list-style: none;
  }

  @media (min-width: $medium-screen) {
    &__wrapper {
      background: linear-gradient(
        to right,
        var(--vads-color-white) 0 50%,
        var(--vads-color-primary-alt-lightest) 50% 100%
      );
    }
    &__list {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }
  }

  &__search-tools {
    ul {
      list-style: none;
    }
  }
}

.homepage-blog {
  &__image {
    height: 100%;

    // remove when moving away from prototype image
    &.demo-image-adjustments {
      overflow: hidden;
      img {
        transform: scale(170%) translateY(-35px);
      }
    }

    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
      object-position: top;
      display: block;

      @media (min-width: $small-screen) {
        height: 100%;
      }
    }
  }
}

.email-update-signup-form {
  &__input {
    height: 35px;
    max-width: none;
  }
  &__button {
    height: 35px;
    margin-left: 0;
    border-radius: 5px;
    @media (min-width: $medium-screen) {
      margin-left: -5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
