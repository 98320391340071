.hub-links-list {
  padding-right: 5px;
  padding-left: 0.625rem;
  list-style: none;
}

.homepage-hub {
  @include linear-gradient-background(var(--vads-color-primary), var(--vads-color-primary-darker));
  padding-top: 1.5em;

  @include media($medium-screen) {
    padding-top: 3em;
  }
}

.homepage-hub-container {
  max-width: 62.5rem;

  @include media($medium-screen) {
    margin: auto;
    width: 85%;
  }

  @include media($large-screen) {
    margin: auto;
    width: 64%;
  }
}

.homepage-heading {
  color: var(--vads-color-white);
  text-align: center;
  margin-top: 0;
  margin-bottom: 1em;

  @include media($medium-screen) {
    margin-bottom: 1.5em;
  }
}

.hub-links {
  &-row {
    display: flex;
    flex-direction: column;
    @include media($medium-screen) {
      flex-direction: row;
    }
  }

  &-container {
    background-color: var(--vads-color-white);
    margin: 0 0.5em 0.5em;
    padding: 1.5em 1.5em 2.125em 1.5em;

    @include media($medium-screen) {
      margin: 0 21px 21px 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

#homepage-benefits {
  padding-top: 1.5em;

  @include media($medium-screen) {
    padding-top: 3em;
    padding-left: 1em;
    padding-right: 1em;
  }
}

// The !importants here are due to the
// usa-grid padding 0 rule
.homepage-benefits-row {
  padding-bottom: 1.5em;
  &:last-of-type {
    padding-bottom: 0;
  }
  @include media($medium-screen) {
    padding-bottom: 3em;
  }
}

#homepage-popular {
  padding-top: 3em;
  padding-left: 0.5em;
  padding-right: 0.5em;

  @include media($medium-screen) {
    padding-left: 1em;
    padding-right: 1em;
  }

  @include media($large-screen) {
    padding-left: 0;
    padding-right: 0;
  }
}

#homepage-news {
  padding: 3em 0.5em;

  @include media($medium-screen) {
    padding-left: 1em;
    padding-right: 1em;
  }

  @include media($large-screen) {
    padding-left: 0;
    padding-right: 0;
  }
}

// new pattern, but specific to homepage only
.homepage-button {
  width: 100%;
  height: 85px;
  padding: 0;
  margin: 0 0 16px;
  display: inline-block;
  color: var(--vads-color-white);
  background-color: var(--vads-color-primary);
  text-decoration: none;
  border-radius: 5px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: background-color;

  &:hover, &:active {
    background-color: var(--vads-color-primary-dark);
    outline-offset: 2px;
    .icon-wrapper {
      background-color: var(--vads-color-primary-darker);
    }
  }

  &:hover, &:focus, &:visited {
    color: var(--vads-color-white);
    text-decoration: none;
    // background-color: var(--vads-color-primary);
    outline-offset: 2px;
  }

  &.vcl {
    background-color: var(--vads-color-secondary-dark);
    &:hover, &:active {
      background-color: var(--vads-color-secondary-darkest);
      outline-offset: 2px;
      .icon-wrapper {
        background-color: var(--vads-color-secondary-dark);
        outline-offset: 2px;
      }
    }
  }

  .icon-wrapper {
    width: 55px;
    height: 100%;
    float: left;
    background-color: var(--vads-color-primary-dark);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-property: background-color;

    &.vcl {
      content: "";
      background: url(/img/VCL-icon-white.svg) no-repeat;
      background-size: 2em auto;
      background-position: center;
      background-color: var(--vads-color-secondary-darkest);
    }
  }

  .button-inner {
    margin-left: 55px;
    height: 100%;
    padding: 1em;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

}

.homepage-news-story {
  margin-bottom: 2em;
  @include media($medium-screen) {
    margin-bottom: 0em;
  }
}

.homepage-news-story-title {
  margin-top: 1em;
  margin-bottom: 0;
  @include media($medium-screen) {
    margin-top: 1.5em;
    margin-bottom: inherit;
  }
}

.homepage-news-story-desc {
  margin-top: 0.5em;
  margin-bottom: 0;
  @include media($medium-screen) {
    margin-bottom: inherit;
  }
}

.homepage-image-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 65%; /* You define this doing height / width * 100% */
  position: relative;
  > .lazy {
    width: 100%;
    /*height: auto;*/
    position: absolute;
  }
}
