@import "~@department-of-veterans-affairs/css-library/dist/tokens/scss/variables";
@import "~@department-of-veterans-affairs/css-library/dist/stylesheets/mixins";

/* Adds additional extensions to .usa-button */
.usa-button-darker {
  background: var(--vads-color-primary-dark);
  &:hover,
  &:focus {
    background: var(--vads-color-primary-darker);
  }
}

.va-button-link {
  @include button-link;
}