.downtime-notification {
  &[data-status="down"] {
    margin-bottom: 4em;
    h3 {
      margin-top: 0;
      margin-bottom: 1em;
    }
  }

  // &[data-status="downtimeApproaching"] {
  // }
}
