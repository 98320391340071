@import "~@department-of-veterans-affairs/css-library/dist/stylesheets/modules/va-pagination";

// Facilities Hub
.vads-facility-hub-cta {
  border-top: 1px solid var(--vads-color-primary-alt-light);
  height: 73px;
  position: relative;

  & > a {
    text-decoration: none;
    display: inline-block;
  }
}

.vads-facility-hub-cta-label {
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  margin-left: 48px;
}

.vads-facility-hub-cta-circle {
  background-color: var(--vads-color-primary-alt-lightest);
  border-radius: 50%;
  padding: 6px;
}

.vads-facility-hub-cta-arrow {
  font-size: 10px;
  padding-left: 4px;
}

.vads-facility-hub-cta-last-line {
  border-bottom: 1px solid var(--vads-color-primary-alt-light);
}