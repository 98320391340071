.rotate-icon-container {
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#rotating-va-loading-icon {
  color: var(--vads-color-white);
}