#hub-rail {
  padding-top: 6.3em;
}

.merger-r-rail {

  border: 3px solid var(--vads-color-base-lightest);
  margin-bottom: 1.5em;

  .rail-heading {
    padding: 1em;
    background-color: var(--vads-color-base-lightest);
  }

  &:last-child {
    margin-bottom: 3em !important;
  }

  &.hub-promo {
    background-color: $mm-gray;
    border: none;

  }
  .hub-promo-text {
    padding: 1em;
  }

  a {
    text-decoration: none;
  }

  h4 {
    margin-top: 0;
    a{
      text-decoration: underline;
    }
  }

  .va-nav-linkslist-list {
    padding: 1em;
    background-color: var(--vads-color-white);

    li {
      margin: 0;
      p {
        margin-bottom: 0;
        margin-top: 0.3125rem;
      }
    }
  }

  .social {

    li {
      margin-bottom: 1em;
    }
  }
}
