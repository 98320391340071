.va-crisis-line-container {
  position: relative;
  margin: auto;
  background-color: var(--vads-color-secondary-darkest);

  @include media($small-desktop-screen) {
    max-width: 975px;
    background-color: transparent;
  }
}

button.va-crisis-line {
  background-color: var(--vads-color-secondary-darkest);
  display: block;
  border-radius: 0;
  color: var(--vads-color-white);
  font-size: 16px;
  font-weight: normal;
  text-decoration: none;
  padding: 0;
  margin: 0;
  width: 100%;

  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: background-color;

  &:focus {
    outline-offset: -2px;
  }

  &:hover,
  &:active {
    background-color: var(--vads-color-secondary-darkest);

    .va-crisis-line-icon {
      @include media($small-desktop-screen) {
        background-color: var(--vads-color-secondary-dark);
      }
    }
  }

  @include media($small-desktop-screen) {
    width: auto;
    position: absolute;
    right: 0;
    bottom: -12px;
    border-radius: 0 0 0.2em 0.2em;
    box-shadow: 0 2px 5px var(--vads-color-base);
  }
}

.va-crisis-line-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  margin: auto;

  @include media($small-desktop-screen) {
    text-align: left;
    justify-content: space-between;
  }
}

.va-crisis-line-icon {
  content: "";
  background: url(/img/VCL-icon-white.svg) no-repeat right 29%;
  background-size: 1.75em auto;
  background-position: 0.5em 0.5em;
  width: 2.5em;
  height: 2em;
  padding: 0.3em 1em;

  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: background-color;

  @include media($small-desktop-screen) {
    background-color: var(--vads-color-secondary-darkest);
    background-position: 0.4em 0.5em;
    border-bottom-left-radius: 0.2em;
  }
}

.va-crisis-line-text {
  padding: 0.5em;
}

.va-crisis-line-arrow {
  height: 13px;
  width: 29px;
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.va-crisis-panel {
  color: var(--vads-color-base);
  font-size: 16px;

  li:nth-child(odd) {
    border-bottom: none;
  }

  &-body {
    padding: 1.5em;
    max-height: 100%;
  }

  &-title {
    color: var(--vads-color-base);
    padding-left: 41px;
    background: url(/img/vcl-logo.png) no-repeat left;
    background-position: left 5px;
    background-size: 32px auto;
    margin-top: 0;
    width: 90%;
    font-size: 20px;
  }

  &-icon {
    color: var(--vads-color-base) !important;
    font-size: 26px;
    width: 46px;
    padding: 0.4em 0.3em;
    align-self: center;
    flex-shrink: 0;
  }

  &-list {
    list-style: none;
    padding: 0;

    > li {
      display: flex;
      min-height: 56px;
      margin-bottom: 0;
      border-top: 1px solid var(--vads-color-base-light);

      &:last-child {
        border-bottom: 1px solid var(--vads-color-base-light);
      }

      > a {
        color: var(--vads-color-link);
        text-decoration: underline;
        align-self: center;
      }

      > p {
        color: var(--vads-color-link);
        text-decoration: underline;
        margin-bottom: 0;
      }

      > p > a {
        color: var(--vads-color-link);
        padding: 0;
      }
    }
  }

  &-icon {
    color: var(--vads-color-base) !important;
    font-size: 26px;
    width: 46px;
    padding: 0.4em 0.3em;
    align-self: center;
    flex-shrink: 0;
}

  &-close.va-modal-close {
    font-size: 2.5rem;
    color: var(--vads-color-link);
    padding-top: 0.0625rem;
    &:hover {
      fill: var(--vads-color-black);
    }
  }
}

.va-modal-large .va-crisis-panel.va-modal-inner {
  width: 90vw;
  max-width: 46.875rem;
}

.va-crisis-panel-close:hover svg path {
  fill: var(--vads-color-black);
}

.va-overlay-close#close-button-icon {
  pointer-events: none;
}