$font-path: "~/src/site/assets/fonts";

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    src: url("../../../site/assets/fonts/sourcesanspro-regular-webfont.ttf") format("truetype");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    src: url("../../../site/assets/fonts//sourcesanspro-bold-webfont.ttf") format("truetype");
}